<template>
  <v-list dense>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="nav-tab-heading">Gruppen</v-list-item-title>
      </v-list-item-content>

      <v-list-item-action>
        <temeno-circle-button @click="$emit('new-group')">
          $plus
        </temeno-circle-button>
      </v-list-item-action>
    </v-list-item>

    <div
      class="px-1 py-5 text-center"
      style="font-size: 14px"
      v-if="groups.length === 0"
    >
      Sie haben bisher noch<br />
      keine Gruppe angelegt
    </div>

    <navigation-group-list-item
      v-for="group in groups"
      :key="group.id"
      :group="group"
      @edit="onNavigationGroupListItemEdit"
    ></navigation-group-list-item>
  </v-list>
</template>

<script>
export default {
  name: "NavigationGroupList",
  components: {
    NavigationGroupListItem: () => import("./NavigationGroupListItem"),
    TemenoCircleButton: () => import("@/components/TemenoCircleButton"),
  },
  data() {
    return {
      groupEditor: {
        group: null,
        show: false,
      },
    };
  },
  computed: {
    groups() {
      return this.$store.getters["groups/all"];
    },
  },
  methods: {
    onNavigationGroupListItemEdit(group) {
      this.$emit("edit-group", group);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";

.nav-tab-heading {
  color: $color-primary;
}

.theme--dark {
  &.v-list-item {
    padding: 0 6px;
  }
}
</style>
